.background-image {
    background-image: url('./images/bg.png');
    background-size: cover;
    background-position: center;
    height: 800px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}
.contact-image {
    background-image: url('./images/contact-bg.png');
background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.offcanvas{
    background-color: black !important;
}
.btn-close{
    background-color: white !important;
}
.nav-link{
    color: #FFFFFF;
}



.contact-text-left{
    margin-top: 100px;
    margin-bottom: 80px;
    font-family: "Poppins", sans-serif !important;
}

.transparent-navbar {
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    z-index: 3;
}

.nav-links .nav-link {
    color: white !important;
    font-size: 14px;
    font-weight: bold;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
}

.nav-links .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    height: 2px;
    background-color: white;
    width: 0;
    transition: width 0.3s ease-in-out;
}

.nav-links .nav-link:hover::after {
    width: 100%;
}

h1 {
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
}

.btn-primary{
        text-transform: uppercase;
        font-style: normal;
        text-decoration: none;
        line-height: 18px;
        letter-spacing: 1.6px;
        text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
        fill: #C85C0E;
        color: #C85C0E;
        background-color: #FCFCFC;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #FFFFFF;
        border-radius: 30px 30px 30px 30px;
        padding: 17px 54px 17px 54px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 1.9px !important;
    font-size: 14px !important;
}
.btn-primary:hover {
    background-color: #ff7f50;
    border: none;
}
.video{
margin-top: 200px;
text-align: left;
}

@media (max-width: 576px) {
    .video{
        margin-top: 50px;
        text-align: left;
        }
        .btn-primary{
            margin-bottom: 40px;
        }
        h1 {
            color: white;
            font-size: 1.5rem;
            font-weight: bold;
        }
        .background-image {
            background-image: url('./images/bg.png');
            background-size: cover;
            background-position: center;
            height: 90vh;
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
        }
        .backgroundcard-image{
            background-size: cover;
            background-position: center;
            height: 40vh;
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
        }
        
}
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio */
    height: 0;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
